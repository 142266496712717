import { Card } from "@mui/material";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { IMatch } from "src/domain/match";
import './match-card.scss';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Link } from 'react-router-dom';

export interface IMatchCardProps {
    match?: IMatch;
}

dayjs.extend(customParseFormat);

export function MatchCard(props: IMatchCardProps) {
    const localDate = dayjs(props.match?.localDateTime, 'DD-MM-YYYY HH:mm:ss').format('dddd, MMMM D, YYYY, HH:mm');
    const winningResult = `${props.match?.matchScore.winningTeamResultText} ${props.match?.matchScore.resultTextTranslationKey} ${props.match?.matchScore.matchResultScoreText}`
    const isLive = props.match?.matchDataStatusType.toLowerCase().includes('live');

    return (
        <Card className="match-card" component={Link} to={`/match/${props.match?.registrationKey}`}>
            { !isLive ? <div className="match-number">{props.match?.matchNumber}</div> : <div className="live"><div className="live-text">Live</div></div>}
            <div className="match-details">
                <div className="match-information-container">
                    <div className="match-information">
                        <div className="date">{localDate}</div>
                        <div className="teams">
                            <div className="home-team-card">
                                <span className="home-team-name">{props.match?.homeTeamEdition.teamCode}</span>
                                <img className="flag-card" src={`${props.match?.homeTeamEdition.teamImageUrl}`}></img>
                            </div>
                            <div className="score-card">
                                <span>{props.match?.matchScore.homeFinalScore ?? '-'}</span>
                                <span className="margin">:</span>
                                <span>{props.match?.matchScore.awayFinalScore ?? '-'}</span>
                            </div>
                            <div className="away-team-card">
                                <img className="flag-card" src={`${props.match?.awayTeamEdition.teamImageUrl}`}></img>
                                <span className="away-team-name">{props.match?.awayTeamEdition.teamCode}</span>
                            </div>
                        </div>
                        <div className="winning-result">{winningResult ? winningResult : ""}</div>
                    </div>
                    <div className="arrow">
                        <KeyboardArrowRightIcon/>
                    </div>
                </div>
                <div className="venue">
                        <div>{props.match?.competitionPhaseName }</div>
                        <div>|</div>
                        <div>Round { props.match?.round } </div>
                        <div>|</div>
                        <div>{ props.match?.venueName }, { props.match?.venueCity } ({ props.match?.venueCountryCode })</div>
                </div>
            </div>
        </Card>
    )
}
