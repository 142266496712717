import axios, { AxiosRequestConfig } from "axios";
import dayjs from "dayjs";
import { useApiError } from "./use-api-error";
import { useAuth } from "./use-auth";
import { useLoader } from "./use-loader";

// Create our Axios instance
const api = axios.create({
  withCredentials: true
});

// ID's of previously assigned interceptors
let requestInterceptorId: number, responseInterceptorId: number;

export function useAPI() {
  const { token, reAuthenticate, setToken } = useAuth();
  const { addError } = useApiError();
  const { hideLoader } = useLoader();

  // List of calls waiting on our refresh cycle
  const refreshSubscribers: {resolve: any, reject: any}[] = [];
  
  // Indidates if we're currently refreshing tokens
  let isRefreshing = false;

  /**
   * Utility function to add a failed request to the queue
   * waiting on the refresh cycle
   * @param resolve 
   * @param reject 
   */
  const subscribeTokenRefresh = (resolve: any, reject: any) => {
    console.log('subscribeTokenRefresh()');
    refreshSubscribers.push({resolve, reject});
  }

  /**
   * Gets called when the token is refreshed to work through the queue
   */
  const onRefreshed = (token: any) => {
    console.log('onRefreshed()');
    while (refreshSubscribers.length > 0) {
      const subscriber = refreshSubscribers.pop();
      subscriber?.resolve(token);
    }
  }

  /**
   * Gets called when the token failed to refresh to clear the queue
   */
  const onExpired = () => {
    console.log('onExpired()');
    while (refreshSubscribers.length > 0) {
      const subscriber = refreshSubscribers.pop();
      subscriber?.reject();
    }
    reAuthenticate();
  }

  /**
   * Intercept requests and add our token
   */
  const requestInterceptor = (config: AxiosRequestConfig) => {
    // Only set header if no header was present!
    // Otherwise use the existing header from the refresh cycle!
    if (token && config.headers && !config?.headers?.Authorization) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  };

  /**
   * Intercept responses to listen for 401 errors to start our 
   * refresh cycle
   */
  const responseInterceptor = (error: any) => {
    // Save a copy of our original request
    const originalRequest = error.config;
    // Determine if this was a refresh request
    const isRefreshRequest = error.config.url === '/api/auth/token';

    // Check if refresh token was invalid - Here everything breaks!?
    if ([401, 403, 500].includes(error.response.status) && isRefreshRequest) {
      console.log('Failed to refresh!')
      isRefreshing = false;
      reAuthenticate();
      return false;
    }

    // Check if our access token has expired
    if (error.response.status === 401) {
      console.log('401! isRefreshing:', isRefreshing)
      // Check if we're not already refreshing
      if (!isRefreshing) {
        console.log('Refreshing now!');
        // Indicate that we're refreshing
        isRefreshing = true;
        // Start the request cycle
        api.post<{ accessToken: string }>(`/api/auth/token`, {})
          .then(response => {
            if (response && response.data) {
              // Store our new token
              setToken(response.data.accessToken);
              // Set our new token as the default
              const { accessToken } = response.data; 
              api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
              // Indidate we're no long refreshing
              isRefreshing = false;
              // Trigger calls in the queue with our new token
              onRefreshed(accessToken);
            }
          })
          .catch(error => {
            // Log the error
            console.error(error);
            // Indidate we're no long refreshing
            isRefreshing = false;
            // Trigger to clear the queue
            onExpired();
          })
      }
    } else {
      hideLoader();
      addError({
        status: error.response.data.statusCode,
        message: error.response.data.message,
        timestamp: error.response.data.timestamp || dayjs().format(),
      })
      return Promise.reject({
        ...error,
        message: error.response.data.message ? error.response.data.message : error.message
      });
    }

    // Add our failed request to the retry queue
    const retryOriginalRequest = new Promise((resolve, reject) => {
      subscribeTokenRefresh((accessToken: any) => {
        originalRequest._retry = true;
        originalRequest.headers.Authorization = `Bearer ${accessToken}`;
        return resolve(api(originalRequest))
      }, () => {
        originalRequest._retry = false;
        return reject(api(originalRequest));
      });
    })

    // Return our retry
    return retryOriginalRequest;
  };

  /**
   * Eject interceptors that might have been assigned by other
   * components utilising the hook
   */
   api.interceptors.request.eject(requestInterceptorId);
   api.interceptors.response.eject(responseInterceptorId);
 
   /**
    * (Re)Bind our interceptors
    */
   requestInterceptorId = api.interceptors.request.use(requestInterceptor)
   responseInterceptorId = api.interceptors.response.use(response => response, responseInterceptor)
 
  return {
    api,
    fetcher: <T>(url: string) => api.get<T>(url).then(res => res.data),
  }
}