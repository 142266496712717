import './App.css';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CompetitionEditions } from './views/competition-editions/competition-editions';
import { CompetitionInfo } from './views/competition-info/competition-info';
import { CompetitionGroupInfo } from './shared/components/competition-group-info/competition-group-info';
import { Match } from './views/match/match';
import { useAuth } from './shared/hooks/use-auth';
import { FullScreenLoader } from './shared/components/full-screen-loader/full-screen-loader';

function App() {

  const { token, error } = useAuth();
  if (!token && !error) return <FullScreenLoader />;

  return (
    <div className="App">
      <Switch>
        <Route path="/competitioneditions">
          <CompetitionEditions/>
        </Route>
        <Route path="/competitioninfo/:id">
          <CompetitionInfo/>
        </Route>
        <Route path="/competitiongroupinfo">
          <CompetitionGroupInfo/>
        </Route>
        <Route path="/match/:id">
          <Match />
        </Route>
        <Route path="/">
          <Redirect to={`/competitioneditions`}></Redirect>
        </Route>
      </Switch>
    </div>
  );
}

export default App;
