import { Box, Tab, Tabs } from "@mui/material";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Lineup } from "../../shared/components/lineup/lineup";
import { Timeline } from "../../shared/components/timeline/timeline";
import { MatchReports } from "../../shared/components/match-reports/match-reports";
import { IMatchHeader } from "src/domain/match-header";
import { IStage } from "../../domain/stage";
import './match.scss'
import { FullScreenLoader } from "../../shared/components/full-screen-loader/full-screen-loader";
import { useAPI } from "../../shared/hooks/use-api";
import useSWR from "swr";

export interface IParam {
    id: string;
}

dayjs.extend(customParseFormat);

export function Match() {
    let param = useParams<IParam>();

    const [value, setValue] = useState(0);
    const handleChange = (event: any, newValue: any) => {
        setValue(newValue);
    };

    const { fetcher } = useAPI();
    const { data: match } = useSWR<IMatchHeader>(`/api/match/getMatchDetailHeader/${param.id}`, fetcher);
    const localDate = dayjs(match?.localDateTime, 'DD-MM-YYYY HH:mm:ss').format('dddd, MMMM D, YYYY - HH:mm');
    const isLive = match?.matchDataStatusType.toLowerCase().includes('live');

    const stages: IStage[] = [{
        name: "Lineup",
        keyWord: "lineup"
    }, {
        name: "Timeline",
        keyWord: "timeline"
    }, {
        name: "Match reports",
        keyWord: "reports"
    }];

    return (
        <div>
            { !match ? <FullScreenLoader opacity={0.7} />  :
            <React.Fragment>
            <div className="match-header">
                <div className="teams-top">
                    <div className="home-top"/>
                    <div className="away-top"/>
                </div>
                <div className="header-info">
                    <div className="match-info"> 
                        <div>#{ match?.matchNumber } |</div>
                        <div> { match?.competitionPhaseName } |</div>
                        <div> Round { match?.roundNumber }</div>
                    </div>
                    <div className="date-header">{ localDate }</div>
                    <div className="venue-header">{ match?.venueDisplayName }</div>
                </div>
                <div className="teams-header">
                    <div className="home">
                            <span className="name-team">{ match?.homeTeamEdition.name }</span>
                            <img className="flag" src={ match ? `https://fdp-test.ortec.com${match?.homeTeamEdition.teamImageUrl}` : ''}></img>
                            <span className="score">{ match?.matchScore.homeFinalScore }</span>
                    </div>
                    <div className="away">
                        <span className="score">{ match?.matchScore.awayFinalScore }</span>
                        <img className="flag" src={match ? `https://fdp-test.ortec.com${match?.awayTeamEdition.teamImageUrl}` : ''}></img>
                        <span className="name-team">{ match?.awayTeamEdition.name }</span>
                    </div>
                </div>
                { isLive ? <div className="is-live">Live</div> : null}
            </div>
            <Box sx={{ maxWidth: 480, bgcolor: 'background.paper' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="scrollable force tabs example"
                >
                    {stages.map((stage) => {
                        return <Tab key={stage.keyWord} label={stage.name} />
                    })}
                    
                </Tabs>
            </Box>
            
            { stages[value].keyWord.includes("lineup") ?  <Lineup key="lineup" matchKey={param.id} homeTeamImageUrl={match?.homeTeamEdition.teamImageUrl} homeTeamName={match?.homeTeamEdition.name} awayTeamImageUrl={match?.awayTeamEdition.teamImageUrl} awayTeamName={match?.awayTeamEdition.name}/> : <></> }
            { stages[value].keyWord.includes("timeline") ? <Timeline key="timeline" matchKey={param.id}/> : <></> }
            { stages[value].keyWord.includes("reports") ? <MatchReports key="reports" matchKey={param.id}/> : <></> }
            </React.Fragment> }
        </div>
    )
}