import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { CompetitionGroupInfo } from "../../shared/components/competition-group-info/competition-group-info";
import { ICompetitionEdition } from "src/domain/competition-edition";
import { ICompetitionPhasesResponse } from "src/domain/responses/competition-phases-response";
import { FullScreenLoader } from "../../shared/components/full-screen-loader/full-screen-loader";
import { useAPI } from "../../shared/hooks/use-api";
import useSWR from "swr";

export interface IParam {
    id: string;
}

export function CompetitionInfo() {
    let param = useParams<IParam>();
    const [value, setValue] = useState(0);

    const handleChange = (event: any, newValue: any) => {
        setValue(newValue);
    };

    const { fetcher } = useAPI();
    const { data: competition } = useSWR<ICompetitionEdition>(`/api/competition/getCompetitionEdition/${param.id}`, fetcher);
    const { data: competitionPhases } = useSWR<ICompetitionPhasesResponse>(`/api/competition/getCompetitionEdition/${param.id}/phases`, fetcher);

    const phase = competitionPhases?.competitionGroups.find((phase) => phase.competitionGroupName === competitionPhases?.competitionGroups[value].competitionGroupName);

    return(
        <div>
            {!competition ? <FullScreenLoader opacity={0.7} /> :
            <React.Fragment>
                <h4 className="title">{ competition?.competitionEditionName }</h4>
                <Box sx={{ maxWidth: 480, bgcolor: 'background.paper' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile
                        aria-label="scrollable force tabs example"
                    >
                        {competitionPhases?.competitionGroups.map((phase) => {
                            return <Tab key={phase.competitionGroupKind} label={phase.competitionGroupName} />
                        })}
                        
                    </Tabs>
                </Box>
                { !phase ? <FullScreenLoader opacity={0.7} />  : <CompetitionGroupInfo key={value} phaseKeys={phase?.competitionPhasesKeys}/>}
            </React.Fragment>}
        </div>
    )
}