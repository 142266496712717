import { IAsset } from "src/domain/asset";
import { IReportItem } from "src/domain/report-item";
import './asset-item.scss';
import * as fileSaver from 'file-saver';
import { useAPI } from "../../hooks/use-api";
import useSWR from "swr";
import { FullScreenLoader } from "../full-screen-loader/full-screen-loader";
import { useState } from "react";

export interface IAssetItemProps {
    item?: IReportItem;
}

export function AssetItem(props: IAssetItemProps) {
    const [value, setValue] = useState(false);
    const { api } = useAPI();
    const { fetcher } = useAPI();
    const { data: assetItem } = useSWR<IAsset>(`/api/match/getAssetItem/${props.item?.assetKey}`, fetcher);

    function download(){ 
    setValue(true);
    api({
        url: `/api/match/getFile`,
        method: 'POST',
        data: {
            downloadLink: assetItem?.downloadLink
        },
        responseType: 'arraybuffer',
    }).then((response: any) => {
        setValue(false);
        const blob = new Blob([response.data], {type: 'application/pdf'})
        fileSaver.saveAs(blob, assetItem?.fileName);
    })
}

    return(
        <div className="report">
            <div onClick={() => download()} >
                {props.item?.language.toLowerCase().includes("english") ? 'EN' : props.item?.language.toLowerCase().includes("german") ? 'DE' : props.item?.language.toLowerCase().includes("french") ? 'FR' : props.item?.language.toLowerCase().includes("arabic") ? 'AR' : 'ES' }
            </div>
            { value? <FullScreenLoader opacity={0.7} /> : <></> }
        </div>
    )
}
