import { useState, useEffect, useCallback } from "react";
import { useQuery } from "./use-query";
import { generateCode } from "../utils";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { LocalStorageTypes, useLocalStorage } from "./use-local-storage";
import { useHistory, useLocation } from "react-router";

export interface IUser {
  _id: string;
  magazineId: number;
  language: string;
  platformUserId: string;
  metadata: any;
}

export function useProvideAuth() {
  const query = useQuery();
  const history = useHistory();
  const { pathname } = useLocation();
  const platformToken = query.get('token');

  let uidFromToken: string | null = null;

  if (platformToken) {
    const decodedToken: any = jwt_decode(platformToken);
    uidFromToken = decodedToken.uid
  }

  const [token, setToken] = useState<string | null>(null);
  const [user, setUser] = useLocalStorage('ress.user', null, LocalStorageTypes.Object);
  const [language] = useLocalStorage('ress.language', query.get('language') || 'en');
  const [magazineId] = useLocalStorage('ress.magazineId', query.get('magazine'), LocalStorageTypes.Number);
  const [osName] = useLocalStorage('ress.osName', query.get('os_name'));
  const [appScheme] = useLocalStorage('ress.appScheme', query.get('app_scheme'));
  const [uid] = useLocalStorage('ress.uid', uidFromToken);
  const [code, setCode] = useState(query.get('code'));
  const [error] = useState(query.get('error'));
  const [errorDescription] = useState(query.get('error_description'));
  const [state, setState] = useState(query.get('state'));
  const [isExchanging, setIsExhanging] = useState<boolean>(false);
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false);

  const refreshAccessToken = useCallback(async () => {
    console.group('[Refreshing Token]');
    return await axios.post(`/api/auth/token`, {}).then((response:any) => {
      console.log('Status: \t\t\t', response.status);
      setToken(response.data.accessToken);
      console.groupEnd();
      return response.data.accessToken;
    }).catch(error => {
      console.log('Status: \t\t\t', error.message);
      console.groupEnd();
      return false;
    });
  }, [setToken])

  const redirectToAuthProvider = useCallback(async () => {
    setIsRedirecting(true);
    /**
     * First try if we can refresh our token,
     * if not, simply trigger our login.
     */
    const accessToken = await refreshAccessToken();
    if (accessToken) return true;

    console.group('[Redirect]');
    console.log('Language: \t\t', language);
    console.log('Magazine ID: \t', magazineId);
    console.log('OS Name: \t\t', osName);
    console.log('App Scheme: \t', appScheme);
    console.log('Uid: \t', uid);
    console.groupEnd();

    /**
     * If we don't have enough data to perform a login
     * stop init!
     */
    if (!magazineId || !language || !osName || !uid) {
      console.error('Required login data missing!', {magazineId, language, osName, uid});
      alert('Required login data missing!');
      throw new Error('Required login data missing!');
    }

    console.log('Sending user to login...')
    const domain = process.env.REACT_APP_API_REDIRECT_URL || window.location.origin;
    const loginUrl =  `${domain}/api/auth/start` +
                      `?magazine_id=${magazineId}` +
                      `&language=${language}` +
                      `&state=${generateCode(15, '#aA')}` +
                      `&uid=${uid}` +
                      `&app_scheme=${appScheme}` +
                      `&os_name=${osName}`;

    window.open(loginUrl, '_self');
  }, [refreshAccessToken, magazineId, language, appScheme, osName, uid])

  const exchangeCodeForToken = useCallback(async () => {
    if (!isExchanging && !error) {
      setIsExhanging(true);

      console.group('[Exchange]');
      console.log('Code: \t\t\t', code);
      console.log('State: \t\t\t', state);
      console.groupEnd();

      const tokenRequest: any = await axios.post(`/api/auth/exchange`, {code, state});
      const { accessToken } = tokenRequest.data;
      const userRequest = await axios.get(`/api/auth/me`, {headers: {'Authorization': `Bearer ${accessToken}`}});
      const user = userRequest.data;

      setCode(null);
      setState(null);
      setToken(accessToken);
      setUser(user);
      setIsExhanging(false);
      setIsRedirecting(false);
    }
  }, [isExchanging, setUser, setToken, setCode, setState, code, state, error])

  useEffect(() => {
    if (error) {
      if (!pathname.startsWith('/errors')) history.push(`/errors`)
    } else if (!isExchanging) {
      if (code && state) {
        exchangeCodeForToken();
      } else if(!token && !isRedirecting) {
        redirectToAuthProvider();
      }
    }
  }, [token, redirectToAuthProvider, exchangeCodeForToken, code, state, isExchanging, isRedirecting, error, history, pathname]);

  return {
    token,
    user,
    error,
    errorDescription,
    setToken,
    setUser,
    reAuthenticate: redirectToAuthProvider,
  }
}