import axios, { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { IMatch } from "src/domain/match";
import { useAPI } from "../../hooks/use-api";
import { MatchCard } from "../../../shared/components/match-card/match-card";
import { FullScreenLoader } from "../full-screen-loader/full-screen-loader";

export interface ICompetitionGroupInfoProps {
    phaseKeys?: string[];
}

export function CompetitionGroupInfo(props: ICompetitionGroupInfoProps) {
    const { api } = useAPI();
    const [matches, setMatches] = useState<IMatch[]>();

    let data = JSON.parse(JSON.stringify({competitionPhaseKeys: props.phaseKeys}))

    useEffect( () => {
        let unmounted = false;

        api
        .post<IMatch[]>(`/api/match/getMatchesForPhase`, data)
        .then((response: AxiosResponse) => {
            if (!unmounted) {
                setMatches(response.data as IMatch[]);
            }
        });

        return () => { unmounted = true };
    }, [api]);

    return (
        <div>
            {!matches ? <FullScreenLoader opacity={0.7} /> :
            <div>
                {matches?.map((item: IMatch) => {
                    return <MatchCard key={item.registrationKey} match={item}/>;
                })}
            </div>}
        </div>
    )
}