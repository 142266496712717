import './full-screen-loader.scss';
import { Spinner } from "../spinner/spinner";

interface IFullscreenLoaderProps {
  text?: string;
  opacity?: number;
}

export function FullScreenLoader(props: IFullscreenLoaderProps) {
  return (
    <div className="FullScreenLoader" style={{background: `rgba(255, 255, 255, ${props.opacity ?? 0.8})`}}>
      {props.text ? <span>{props.text}</span> : null}
      <Spinner></Spinner>
    </div>
  )
}