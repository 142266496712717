import { IReportType } from "src/domain/report-type";
import { IMatchReportsResponse } from "src/domain/responses/match-reports-response";
import useSWR from "swr";
import { useAPI } from "../../hooks/use-api";
import { FullScreenLoader } from "../full-screen-loader/full-screen-loader";
import { ReportAsset } from "../report-asset/report-asset";
import './match-reports.scss'

export interface IMatchReportsProps {
    matchKey?: string;
}

export function MatchReports(props: IMatchReportsProps) {
    const { fetcher } = useAPI();
    const { data: matchReports } = useSWR<IMatchReportsResponse>(`/api/match/getMatchReports/${props.matchKey}`, fetcher);

    return (
        <div>
            {!matchReports ? <FullScreenLoader opacity={0.7} /> : 
            <div className="padding">
                {matchReports?.reportTypes.map((report: IReportType, i: number) => {
                    return <ReportAsset key={i} asset={report} />
                })}
            </div>}
        </div>
    )
}