import { createContext, useState } from "react";
import { FullScreenLoader } from "../components/full-screen-loader/full-screen-loader";

export interface ILoader {
  opacity?: number;
  text?: string;
}

export interface ILoaderContext {
  setLoader: Function;
  hideLoader: Function;
}

export const LoaderContext = createContext<ILoaderContext>({
  setLoader: (loader: ILoader) => {},
  hideLoader: () => {},
})

export function ProvideLoader({children}: any) {
  const [showLoader, setShowLoader] = useState(false);
  const [currentLoader, setCurrentLoader] = useState<ILoader>();

  function setLoader(loader: ILoader) {
    setCurrentLoader(loader);
    setShowLoader(true);
  }

  function hideLoader() {
    setShowLoader(false);
  }

  const contextValue = {
    setLoader,
    hideLoader,
  }
  
  return <LoaderContext.Provider value={contextValue}>
    {showLoader && currentLoader ? <FullScreenLoader opacity={currentLoader.opacity} text={currentLoader.text} /> : null}
    {children}
  </LoaderContext.Provider>;
}