import { createContext } from "react";
import { IUser, useProvideAuth } from "../hooks/use-provide-auth";

export interface IAuthContext {
  token: string | null;
  user: IUser | null;
  setToken: Function;
  setUser: Function;
  reAuthenticate: Function;
  error: string | null;
  errorDescription: string | null;
}

export const AuthContext = createContext<IAuthContext>({
  token: null,
  user: null,
  setToken: () => {},
  setUser: () => {},
  reAuthenticate: () => {},
  error: null,
  errorDescription: null,
});

export function ProvideAuth({children}: any) {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}