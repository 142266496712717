import { IMatchEvents } from "src/domain/match-events";
import './timeline.scss'
import goalFromField from '../../../images/icons/events/20.svg';
import substitution from '../../../images/icons/events/21.svg';
import yellowCard from '../../../images/icons/events/18.svg';
import penalty from '../../../images/icons/events/penalty.svg';
import { FullScreenLoader } from "../full-screen-loader/full-screen-loader";
import { useAPI } from "../../hooks/use-api";
import useSWR from "swr";

export interface ITimelineProps {
    matchKey?: string;
}

export function Timeline(props: ITimelineProps) {
    const { fetcher } = useAPI();
    const { data: timeline } = useSWR<IMatchEvents>(`/api/match/getMatchMainEvents/${props.matchKey}`, fetcher);
    const events = timeline?.events.sort((a, b) => a.displayMinute + a.additionalTimeInMinutes < b.displayMinute + b.additionalTimeInMinutes ? 1 : -1)

    return (
        <div>
            {!timeline ? <FullScreenLoader opacity={0.7} /> : 
            <div className="timeline">
                <div className="phase">
                    {events?.map((event, i) => {
                        if (event.positioning.toLowerCase().includes("home")) {
                            return <div key={i} className="event">
                                        <div>
                                            {event.additionalTimeInMinutes !== 0 ? <span className="minute">+{ event.additionalTimeInMinutes }'</span> : <span className="minute">{ event.displayMinute }'</span> }
                                            <img className="icon" src={event.eventType.toLowerCase().includes("goal") ? goalFromField : event.eventType.toLowerCase().includes("substitution") ? substitution : event.eventType.toLowerCase().includes("penalty") ? penalty : yellowCard } />
                                            <span className="name">
                                                <span>{ event.personDisplayName }</span>
                                                <span className="evt-21">{ event.eventType.toLowerCase().includes("substitution") ? event.relatedPersonDisplayName : "" }</span>
                                            </span>
                                        </div>
                                    </div>
                        } else if (event.positioning.toLowerCase().includes("away")) {
                            return <div key={i} className="event reverse">
                                        <div>
                                            <span className="name">
                                                <span>{ event.personDisplayName }</span>
                                                <span className="evt-21">{ event.eventType.toLowerCase().includes("substitution") ? event.relatedPersonDisplayName : ""  }</span>
                                            </span>
                                            <img className="icon" src={event.eventType.toLowerCase().includes("goal") ? goalFromField : event.eventType.toLowerCase().includes("substitution") ? substitution : yellowCard} />
                                            {event.additionalTimeInMinutes !== 0 ? <span className="minute">+{ event.additionalTimeInMinutes }'</span> : <span className="minute">{ event.displayMinute }'</span> }
                                        </div>
                                    </div>
                        }
                        else {
                            return <div key={i} className="event general">
                                        {event.additionalTimeInMinutes !== 0 ? <span className="phase-end">{`${event.displayMinute}'+${event.additionalTimeInMinutes}`}</span> : <></>}
                                    </div>
                        }
                    })}
                </div>
            </div>}
        </div>
    )

}