import { CompetitionEditionCard } from "../../shared/components/competition-edition-card/competition-edition-card";
import { ICompetitionEdition } from "src/domain/competition-edition";
import { FullScreenLoader } from "../../shared/components/full-screen-loader/full-screen-loader";
import { useAPI } from "../../shared/hooks/use-api";
import useSWR from "swr";

export interface ICompetitionEditions {
    competitionEditions: ICompetitionEdition[];
}

export function CompetitionEditions() {
    const { fetcher } = useAPI();
    const { data: competitionEditions } = useSWR<ICompetitionEditions>("/api/competition/getCompetitionEditions", fetcher);
    const orderedCompetitionEditions = competitionEditions?.competitionEditions.sort((a, b) => a.startDate < b.startDate ? 1 : -1)

    return (
        <div>
            {!orderedCompetitionEditions ? <FullScreenLoader opacity={0.7} /> : 
            <div>
                <h4 className="title">Live</h4>
                <div>
                    {orderedCompetitionEditions?.map((item: ICompetitionEdition) => {
                        return <CompetitionEditionCard key={item.competitionEditionKey} competitionEdition={item}/>;
                    })}
                </div>
            </div>}
        </div>
    )
}