import { IReportType } from "src/domain/report-type";
import { AssetItem } from "../asset-item/asset-item";
import './report-asset.scss'

export interface IReportAssetProps{
    asset?: IReportType
}

export function ReportAsset(props: IReportAssetProps) {

    return( 
        <div className="container">
            <div className="info">
                <div className="title">Report</div>
                <div className="type">{props.asset?.reportType.match(/([A-Z]?[^A-Z]*)/g)!.join(" ")}</div>
                <div className="version">Version: 
                    <span className="version-number"> {props.asset?.version}</span>
                </div>
            </div>
            <div className="assets">
                {props.asset?.items.map((item, i) => {
                    return <AssetItem key={i} item={item}/>
                })}
            </div>
        </div>
    )
}