import React from "react";
import { ILineup } from "src/domain/lineup";
import useSWR from "swr";
import { useAPI } from "../../hooks/use-api";
import { FullScreenLoader } from "../full-screen-loader/full-screen-loader";
import './lineup.scss'

export interface ILineupProps{
    matchKey?: string
    homeTeamName: string;
    homeTeamImageUrl: string;
    awayTeamName: string;
    awayTeamImageUrl: string;
}

export function Lineup(props: ILineupProps) {
    const { fetcher } = useAPI();
    const { data: lineup } = useSWR<ILineup>(`/api/match/getMatchLineup/${props.matchKey}`, fetcher);
    const homeStartingPlayers = lineup?.homeTeam.players.filter(player => player.playerClassification.toLowerCase().includes("starting"));
    const homeSubstitutesPlayers = lineup?.homeTeam.players.filter(player => player.playerClassification.toLowerCase().includes("substitutes"));
    const awayStartingPlayers = lineup?.awayTeam.players.filter(player => player.playerClassification.toLowerCase().includes("starting"));
    const awaySubstitutesPlayers = lineup?.awayTeam.players.filter(player => player.playerClassification.toLowerCase().includes("substitutes"));

    return (
        <div>
            {!lineup ? <FullScreenLoader opacity={0.7} /> : 
            <div className="lineup">
                <div className="country-bar">
                        <img className="flag" src={`https://fdp-test.ortec.com${props.homeTeamImageUrl}`}></img>
                        <span className="name-team">{ props.homeTeamName }</span>
                </div>
                <div className="home">
                    <h4 className="lineup-title">Starting</h4>
                    <React.Fragment>
                        {homeStartingPlayers?.map(player => <div className="player" key={player.playerKey}>
                                                                <span className="shirt">{ player.shirtNumber }</span>
                                                                <span className="pos">{ player.positionCode }</span>
                                                                <span className="name">{ player.name }</span>
                                                            </div>)}
                    </React.Fragment>
                    <h4 className="lineup-title">Substitutes</h4>
                    <React.Fragment>
                        {homeSubstitutesPlayers?.map(player => <div className="player" key={player.playerKey}>
                                                                    <span className="shirt">{ player.shirtNumber }</span>
                                                                    <span className="pos">{ player.positionCode }</span>
                                                                    <span className="name">{ player.name }</span>
                                                                </div>)}
                    </React.Fragment>
                    <h4 className="lineup-title">Coaching staff</h4>
                    <React.Fragment>
                        {lineup?.homeTeam.coachingPersons.map(coach => <div className="player" key={coach.personKey}>
                                                                            <span className="name role">{ coach.role.match(/([A-Z]?[^A-Z]*)/g)!.join(" ") }</span>
                                                                            <span className="name">{ coach.name }</span>
                                                                        </div>)}
                    </React.Fragment>
                </div>
                <br/>
                <div className="country-bar">
                        <img className="flag" src={`https://fdp-test.ortec.com${props.awayTeamImageUrl}`}></img>
                        <span className="name-team">{ props.awayTeamName }</span>
                </div>
                <div className="away">
                    <h4 className="lineup-title">Starting</h4>
                    <React.Fragment>
                        {awayStartingPlayers?.map(player => <div className="player-away" key={player.playerKey}>
                                                                <span className="shirt">{ player.shirtNumber }</span>
                                                                <span className="pos">{ player.positionCode }</span>
                                                                <span className="name">{ player.name }</span>
                                                            </div>)}
                    </React.Fragment>
                    <h4 className="lineup-title">Substitutes</h4>
                    <React.Fragment>
                        {awaySubstitutesPlayers?.map(player => <div className="player-away" key={player.playerKey}>
                                                                    <span className="shirt">{ player.shirtNumber }</span>
                                                                    <span className="pos">{ player.positionCode }</span>
                                                                    <span className="name">{ player.name }</span>
                                                                </div>)}
                    </React.Fragment>
                    <h4 className="lineup-title">Coaching staff</h4>
                    <React.Fragment>
                        {lineup?.awayTeam.coachingPersons.map(coach => <div className="player-away" key={coach.personKey}>
                                                                            <span className="name role">{ coach.role.match(/([A-Z]?[^A-Z]*)/g)!.join(" ") }</span>
                                                                            <span className="name">{ coach.name }</span>
                                                                        </div>)}
                    </React.Fragment>
                </div>
            </div>}
        </div>
    )
}